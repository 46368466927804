import BaseService from "../base-service";
import RequestClient from "../request-client";

class ReportService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "reports",
        });
    }

    getCustomerMessagesReport() {
        return RequestClient.get("/api/reports/customer-message-report");
    }

    getOrdersWithoutGamesReport() {
        return RequestClient.get("/api/reports/orders-without-games-report");
    }

    getTipReport() {
        return RequestClient.get("/api/reports/tip-report");
    }

    getReviewReport(payload = {}) {
        return RequestClient.get("/api/reports/review-report", { params: payload });
    }

    getOrderStatusReport(payload = {}) {
        return RequestClient.get("/api/reports/order-status", { params: payload });
    }

    getOrderGuestCustomerSurveysReport(payload = {}) {
        return RequestClient.get("/api/reports/order-guest-customer-surveys-report", { params: payload });
    }
}

export default new ReportService();
