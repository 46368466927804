var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-model-table',{attrs:{"data-function":_vm.getTipReport,"columns":_vm.columns,"show-toolbar":false},scopedSlots:_vm._u([{key:"column_order_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'orders.show', params: { order: row.order_id } }}},[_vm._v(" "+_vm._s((row.order_id))+" ")])]}},{key:"column_order_line_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'jobs.show', params: { job: row.order_line_id } }}},[_vm._v(" "+_vm._s((row.order_line_id))+" ")])]}},{key:"column_customer",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'users.show', params: { user: row.order.customer.user_id } }}},[_vm._v(" "+_vm._s((row.order.customer.name))+" ")])]}},{key:"column_tip_amount",fn:function(ref){
var row = ref.row;
return [_c('v-text',[_vm._v(" "+_vm._s(_vm._f("numberFormat")(row.price))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }