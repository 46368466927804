<template>
    <v-model-table :data-function="getTipReport" :columns="columns" :show-toolbar="false">
        <template v-slot:column_order_id="{ row }">
            <v-link :to="{ name: 'orders.show', params: { order: row.order_id } }">
                {{ (row.order_id) }}
            </v-link>
        </template>

        <template v-slot:column_order_line_id="{ row }">
            <v-link :to="{ name: 'jobs.show', params: { job: row.order_line_id } }">
                {{ (row.order_line_id) }}
            </v-link>
        </template>

        <template v-slot:column_customer="{ row }">
            <v-link :to="{ name: 'users.show', params: { user: row.order.customer.user_id } }">
                {{ (row.order.customer.name) }}
            </v-link>
        </template>

        <template v-slot:column_tip_amount="{ row }">
            <v-text>
                {{ row.price | numberFormat }}
            </v-text>
        </template>
    </v-model-table>
</template>

<script>
import ReportService from "@/services/modules/report-service";

export default {
    data() {
        return {
            getTipReport: () => ReportService.getTipReport(),
            columns: {
                created_at: {
                    label: "Date",
                    fullWidth: false,
                },
                order_id: {
                    label: "Order ID",
                    fullWidth: false,
                },
                order_line_id: {
                    label: "Job ID",
                    fullWidth: false,
                },
                customer: {
                    label: "Customer Name",
                    fullWidth: false,
                },
                tip_amount: {
                    label: "Tip Amount",
                    fullWidth: false,
                    align: "right",
                },
            },
        };
    },
};
</script>
